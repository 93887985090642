export const API_KEY = '819984e59b8d96961c827abc3fd05f1eba6b7913c286261396286225041d80c3';
// export const INSTANCE_URL = 'http://localhost/poultry/index.php/';
 export const INSTANCE_URL = 'https://combinecable.net/apis/';
 export const UPLOADS_URL = 'https://combinecable.net/apis/uploads/';
// export const AUTH_URL = 'http://localhost/poultry/index.php/auth/';
export const AUTH_URL = 'https://combinecable.net/apis/auth/';


export const SMS_CONFIG = {
  UserName : 'ftech-soft',
  Password : '197200',
  URL: 'https://Lifetimesms.com/plain',
  SenderID : 'Future-Tech'
};
