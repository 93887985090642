import { Menu } from './menu.model';
export const verticalMenuItems = [
    new Menu(10, 'Daily Cashbook', 'reports', null, 'tachometer', null, false, 0, [1, 2]),
    new Menu(20, 'Cashbook Book History', 'reports/cashbook-history', null, 'tachometer', null, false, 0, [1, 2]),

    new Menu(100, 'Connections', null, null, 'dot-circle-o', null, true, 0, [1, 2]),
    new Menu(101, 'Connections', 'customers', null, 'plus-circle', null, false, 100, [1, 2]),
    // new Menu(110, 'Post Connection Fees', 'customers/postfee', null, 'plus-circle', null, false, 100, [1, 2]),
    new Menu(120, 'Connections Ledger', 'customers/customeraccts', null, 'plus-circle', null, false, 100, [1, 2]),

    new Menu(200, 'Cash', null, null, 'dot-circle-o', null, true, 0, [1, 2]),
    new Menu(210, 'Cash Receipt', 'cash/recovery', null, 'plus-circle', null, false, 200, [1, 2]),
    new Menu(220, 'Add Expense', 'cash/expense', null, 'plus-circle', null, false, 200, [1, 2]),

    new Menu(300, 'Reports', null, null, 'dot-circle-o', null, true, 0, [1, 2]),
    new Menu(310, 'Pending Recoveries', 'customers/pending', null, 'plus-circle', null, false, 300, [1, 2]),
    new Menu(320, 'Expense Report', 'reports/expense', null, 'plus-circle', null, false, 300, [1, 2]),
    new Menu(330, 'De-activation Requests', 'reports/deactivate', null, 'plus-circle', null, false, 300, [1, 2]),
    new Menu(340, 'Profit Report', 'reports/profit', null, 'plus-circle', null, false, 300, [1, 2]),

    new Menu(500, 'Setup', null, null, 'dot-circle-o', null, true, 0, [1, 2]),
    new Menu(555, 'Areas', 'settings/areas', null, 'plus-circle', null, false, 500, [1, 2]),
    new Menu(552, 'Permission', 'settings/permission', null, 'plus-circle', null, false, 500, [0 , 1 , 2]),
    new Menu(562, 'Products', 'settings/products', null, 'plus-circle', null, false, 500, [1,2]),
    new Menu(564, 'Salesman Products', 'settings/salesman-products', null, 'plus-circle', null, false, 500, [1]),
    new Menu(565, 'Salesman Area', 'settings/salesmanareas', null, 'plus-circle', null, false, 500, [1]),
    new Menu(567, 'Expense Heads', 'settings/heads', null, 'plus-circle', null, false, 500, [1]),
    new Menu(570, 'Admin Users', 'users/list', null, 'plus-circle', null, false, 500, [1]),
    new Menu(570, 'Lineman', 'settings/salesman', null, 'plus-circle', null, false, 500, [1]),
    new Menu(570, 'Setups', 'settings/setups', null, 'plus-circle', null, false, 500, [1]),

];
export const horizontalMenuItems = verticalMenuItems;
