import { Company } from "./factories/constants";
import { Injectable } from "@angular/core";
import { Settings } from "./app.settings.model";
import { HttpBase } from "./services/httpbase.service";

@Injectable()
export class AppSettings {
  public settings = new Settings(
    "DMS",
    "",
    "",
    "",
    {
      menu: "vertical", // horizontal , vertical
      menuType: "default", // default, compact, mini
      showMenu: true,
      navbarIsFixed: true,
      footerIsFixed: false,
      sidebarIsFixed: true,
      showSideChat: false,
      sideChatIsHoverable: true,
      skin: "combined", // light , dark, blue, green, combined, purple, orange, brown, grey, pink
    }
  );
  constructor(private http: HttpBase) {


      this.settings.title ="Combine Cable";
      this.settings.address = "Jalalpur Jattan";
      this.settings.phoneno = "";




  }

}
