<div id="messagesDropDown" class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="messages" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-bell-o" aria-hidden="true"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 p-0 mt-3 box-shadow" aria-labelledby="messages">    
        <div class="clearfix">            
            <ul id="messagesTabs" class="nav nav-tabs nav-justified border-0">
                <li class="nav-item">
                    <a class="nav-link active transition" data-toggle="tab" href="#comments"><i class="fa fa-comment-o" aria-hidden="true"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link transition" data-toggle="tab" href="#files"><i class="fa fa-file" aria-hidden="true"></i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link transition" data-toggle="tab" href="#meetings"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></a>
                </li>                                            
            </ul>
        </div>
        <div class="tab-content" perfectScrollbar>
            <div class="tab-pane active" id="comments">
                <div class="list">
                    <a *ngFor="let message of messages" href="javascript:void(0);" class="transition">
                        <img src="{{ ( message.image || (message.name | profilePicture)) }}">                    
                        <div class="list-content">
                            <h3>{{message.name}} <span class="pull-right"><i class="fa fa-clock-o"></i> {{message.time}}</span></h3>
                            <p>{{message.text}}</p>
                        </div>
                    </a>
                </div> 
            </div>
            <div class="tab-pane" id="files">
                <div class="list">
                    <a *ngFor="let file of files" href class="transition">
                        <h3>{{file.text}} <span class="pull-right">{{file.size}}</span></h3>
                        <div class="progress progress-sm">
                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-{{file.class}}" role="progressbar" [style.width]="file.value"  aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="tab-pane" id="meetings">
                <div class="list">
                    <a *ngFor="let meeting of meetings" href="javascript:void(0);" class="transition">
                        <div class="meeting-day bg-{{meeting.color}} pull-left">
                            <span>{{meeting.day}}</span>
                            <span>{{meeting.month}}</span>
                        </div>                   
                        <div class="list-content">
                            <h2>{{meeting.title}}</h2>
                            <p>{{meeting.text}}</p>
                        </div>
                    </a>
                </div> 
            </div>
        </div> 
    </div>
</div>