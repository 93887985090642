export function TableSetting(cols: any[]) {
  const stngs = {
    selectMode: 'single', // single|multi
    hideHeader: false,
    hideSubHeader: false,
    mode: 'internal',
    actions: {
      columnTitle: 'Actions',
      add: false,
      edit: true,
      delete: true,
      position: 'right' // left|right
    },
    edit: {
      editButtonContent: '<i class="fa fa-pencil mr-3 text-primary"></i>',
      saveButtonContent: '<i class="fa fa-check mr-3 text-success"></i>',
      cancelButtonContent: '<i class="fa fa-times text-danger"></i>',
      confirmSave: true
    },
    add: {
      addButtonContent:
        '<h4 class="mb-1"><i class="fa fa-plus ml-3 text-success"></i></h4>',
      createButtonContent: '<i class="fa fa-check mr-3 text-success"></i>',
      cancelButtonContent: '<i class="fa fa-times text-danger"></i>'
    },
    delete: {
      deleteButtonContent: '<i class="fa fa-trash-o text-danger"></i>',
      confirmDelete: true
    },
    noDataMessage: 'No data found',
    columns: {},
    pager: {
      display: true,
      perPage: 10
    }
  };

  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < cols.length; i++) {
    stngs.columns[cols[i].fldname] = { title: cols[i].title, type: 'string' };
  }

  return stngs;
}
export function RoundTo2(num) {
  return RoundTo(num, 2);
}
export function RoundTo(num: number, dgt: number) {
  return Math.round((num * 1 + Number.EPSILON) * Math.pow(10, dgt)) / Math.pow(10, dgt);
}

export function getCurDate() {
  return new Date();
}

export function getCurrentTime(tim = null) {
  return (
    new Date().getHours() +
    ':' +
    (new Date().getMinutes() + 1) +
    ':' +
    new Date().getSeconds()
  );
}
export function getYMDDate(dte = null) {
  let d = new Date();
  if (dte) {
    d = dte;
  }
  return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
}
export function getDMYDate(dte = null) {
  let d = new Date();
  if (dte) {
    d = dte;
  }
  return d.getDate() + '-' + (1 + d.getMonth()) + '-' + d.getFullYear();
}

export function JSON2Date(d) {
  return d.year + '-' + String(d.month).padStart(2, '0') + '-' + String(d.day).padStart(2, '0');
}

export function GetDateJSON(dte = null) {
  let d = new Date(getCurDate());
  if (dte) {
    d = dte;
  }
  return {
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    day: d.getDate()
  };
}

export function GetProps(source: object, props:any[]) {
  let v:any = {};
  for(let i = 0; i<props.length; i++){
    v[props[i]] = source[props[i]];
   }
   return v;
}
export function getMonthList() {
  return [
    {
      monthno: 1,
      month: 'January'
    },
    {
      monthno: 2,
      month: 'February'
    },
    {
      monthno: 3,
      month: 'March'
    },
    {
      monthno: 4,
      month: 'April'
    },
    {
      monthno: 5,
      month: 'May'
    },
    {
      monthno: 6,
      month: 'June'
    },
    {
      monthno: 7,
      month: 'July'
    },
    {
      monthno: 8,
      month: 'August'
    },
    {
      monthno: 9,
      month: 'September'
    },
    {
      monthno: 10,
      month: 'October'
    },
    {
      monthno: 11,
      month: 'November'
    },
    {
      monthno: 12,
      month: 'December'
    }
  ];
}
export function FindTotal(data, fld) {
  const sum = data.reduce((a, b) => parseFloat(a) + parseFloat(b[fld]), 0);
  return sum;
}

export function getYears() {
  const years = [];
  for (
    let i = new Date().getFullYear() - 1;
    i < new Date().getFullYear() + 3;
    i++
  ) {
    years.push(i);
  }
  return years;
}
