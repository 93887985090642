<div class="dropdown d-inline-block">
    <a class="dropdown-toggle no-caret pl-2 pr-2" id="user-menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-user-o" aria-hidden="true"></i>
    </a>

    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="user-menu">
        <div class="user-menu">
            <div class="user-info text-center p-3">
                <img src="assets/img/users/user.jpg" alt="user-img" class="user-img rounded-circle">                    
                <p class="mt-1 mb-0"> 
                    Emilio Verdines - Web Developer
                    <small>Member since May. 2016</small>
                </p>
            </div>
            <a class="dropdown-item" routerLink="/profile"><i class="fa fa-user mr-2"></i>Profile</a>
            <a class="dropdown-item" routerLink="/"><i class="fa fa-cog mr-2"></i>Settings</a>
            <a class="dropdown-item" routerLink="/"><i class="fa fa-lock mr-2"></i>Lock screen</a>
            <a class="dropdown-item mb-1" routerLink="/login"><i class="fa fa-power-off mr-2"></i>Log out</a>
           
        </div>
    </div>
</div>
