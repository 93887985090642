<nav
  class="navbar box-shadow app-header"
  [class.fixed-top]="settings.theme.navbarIsFixed"
  [class.logo-visible]="showInfoContent"
>
  <div class="d-flex flex-column w-100">
    <!-- <div class="d-flex justify-content-start align-items-center top-navbar" style = 'background-image: url("../../../../assets/img/app/back.jpg")'> !-->
    <div class="d-flex justify-content-start align-items-center top-navbar">
      <a class="logo" routerLink="/" (click)="closeSubMenus()">
        <span class="start"> FT </span
        ><span class="ng">{{ 'CN' }}</span>
      </a>

      <span
        class="navbar-text menu-icon transition ml-3 mr-3"
        (click)="settings.theme.showMenu = !settings.theme.showMenu"
      >
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </span>
      <div class="text-danger">{{ date }}</div>
      <h6
        class="col text-center hidden-xs-down"
        style="color:darkblue; font-family: 'Times New Roman', Times, serif"
      >
        {{ settings.title }}
      </h6>

      <div class="top-rigth-icons ml-auto" *ngIf="cordin">
        <span class="hidden-xs-down">
          <app-user-menu></app-user-menu>
        </span>
      </div>

      <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Deactivate  requests <span style="border:1px solid #fff;" class="badge badge-secondary">{{ data.length }}</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

          <a (click)="navigate('#/reports/deactivate' , item)" class="dropdown-item" *ngFor="let item of data">{{item.name}}({{item.area_name}})</a>
         
        </div>
      </div>
      <div class="top-rigth-icons ml-auto" *ngIf="!cordin">

       


        <a
          style="color:darkblue"
          class="dropdown-item mb-1 hover-item"
          routerLink="/login"
          ><i class="fa fa-power-off mr-2"></i
          ><span class="hidden-xs-down">Log Out</span></a
        >
      </div>
    </div>

    <div
      class="horizontal-menu-outer"
      *ngIf="settings.theme.menu == 'horizontal'"
    >
      <app-horizontal-menu
        *ngIf="showHorizontalMenu"
        [menuItems]="menuItems"
      ></app-horizontal-menu>
      <app-vertical-menu
        *ngIf="!showHorizontalMenu"
        [menuItems]="menuItems"
      ></app-vertical-menu>
    </div>
  </div>
</nav>
