import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private jwtHelp: JwtHelperService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


      const jwtToken = JSON.parse(localStorage.getItem('currentUser'));
        if (jwtToken) {

          if (this.jwtHelp.isTokenExpired(jwtToken.token)) {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
            return false;
          } else {
            return true;
          }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
