<div class="sidebar-outer">
  <a
    class="logo d-flex align-items-center"
    routerLink="/"
    (click)="closeSubMenus()"
    *ngIf="settings.theme.sidebarIsFixed"
  >
   <span class="ng">DMS</span>
  </a>
  <app-vertical-menu [menuItems]="menuItems"></app-vertical-menu>
</div>
